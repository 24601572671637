import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { useState, createContext, ReactNode, useCallback } from 'react'

import { ClientChip } from 'models/clientChips'
import { ClientDevice } from 'models/clientDevices'
import { Client, Identification, Order } from 'models'
import { refresh, updateItemStatus } from '../services/update'
import { show, list, listClientItems } from '../services/read'
import { UseViewClientProps } from 'modules/Clients/hooks/useViewClient'
import { ListResult, QueryOptions, ReadResult } from 'interfaces/queryOptions'

type Props = {
  children: ReactNode
}

export const ClientViewContext = createContext<UseViewClientProps>(
  {} as UseViewClientProps
)

export function ClientViewProvider({ children }: Props) {
  const { clientId } = useParams() as unknown as { clientId: number }

  const history = useHistory()

  const [isLoadingClient, setIsLoadingClient] = useState(true)
  const [client, setClient] = useState<ReadResult<Client>>()

  const [isRefreshing, setIsRefreshing] = useState(false)
  const [identification, setIdentification] =
    useState<Partial<Identification>>()

  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(false)
  const [subscriptions, setSubscriptions] = useState<ListResult<Order>>()

  const [isLoadingOrders, setIsLoadingOrders] = useState(false)
  const [orders, setOrders] = useState<ListResult<Order>>()

  const [isLoadingDevices, setIsLoadingDevices] = useState(false)
  const [devices, setDevices] = useState<ListResult<ClientDevice>>({
    count: 0,
    data: []
  })

  const [isLoadingChips, setIsLoadingChips] = useState(false)
  const [chips, setChips] = useState<ListResult<ClientChip>>({
    count: 0,
    data: []
  })

  const fetchClient = useCallback(async () => {
    if (!clientId) return

    setIsLoadingClient(true)
    try {
      const options = { includes: { statuses: [], country: ['name'] } }

      const data = await show(clientId, options)

      setClient(data)

      if (
        data.attributes.document_type === 'cnpj' &&
        data.attributes.corporate_data
      ) {
        setIdentification({
          document: data.attributes.document,
          ...data.attributes.corporate_data
        })
      }

      setIsLoadingClient(false)
    } catch (error: any) {
      setIsLoadingClient(false)
      history.push('/clients')
      toast.error(
        error.suggestedMessage ?? 'Falha ao recuperar dados do cliente'
      )
    }
  }, [clientId])

  const refreshCorporateData = useCallback(async () => {
    if (!clientId) return

    setIsRefreshing(true)
    try {
      const data = await refresh(clientId)

      if (
        data.attributes.document_type === 'cnpj' &&
        data.attributes.corporate_data
      ) {
        setIdentification({
          document: data.attributes.document,
          ...data.attributes.corporate_data
        })
      }

      setIsRefreshing(false)
    } catch (error: any) {
      setIsRefreshing(false)
      toast.error(
        error.suggestedMessage ?? 'Falha ao atualizar dados do cliente'
      )
    }
  }, [clientId])

  const listSubscriptions = useCallback(
    async (page: number) => {
      if (!clientId) return

      setIsLoadingSubscriptions(true)
      try {
        const options = {
          includes: { plan: ['*'], status: ['id', 'status', 'created_at'] },
          filters: [{ key: 'client_id', op: 'eq', value: clientId }],
          page
        }

        const result = await list<Order>('/subscriptions', options)

        setSubscriptions(result)

        setIsLoadingSubscriptions(false)
      } catch (error: any) {
        setIsLoadingSubscriptions(false)
        toast.error(error.suggestedMessage ?? 'Falha ao buscar assinaturas')
      }
    },
    [clientId]
  )

  const listOrders = useCallback(
    async (page: number) => {
      if (!clientId) return

      setIsLoadingOrders(true)

      try {
        const options = {
          attributes: [
            'id',
            'token',
            'batch',
            'quantity',
            'discount',
            'ordered_at',
            'activated_at'
          ],
          filters: [{ key: 'client_id', op: 'eq', value: clientId }],
          includes: {
            status: ['id', 'status', 'created_at'],
            plan: ['id', 'name', 'nature', 'amount', 'type', 'currency'],
            order_chips: [],
            order_devices: []
          },
          order: {
            ordered_at: 1
          },
          page
        }

        const result = await list<Order>('/orders', options)
        setOrders(result)

        setIsLoadingOrders(false)
      } catch (error: any) {
        setIsLoadingOrders(false)
        toast.error(error.suggestedMessage ?? 'Falha ao buscar pedidos')
      }
    },
    [clientId]
  )

  const listDevices = useCallback(
    async (options: QueryOptions) => {
      if (!clientId) return

      setIsLoadingDevices(true)
      try {
        const result = await listClientItems<ClientDevice>(
          'devices',
          clientId,
          options
        )
        setDevices(result)
        setIsLoadingDevices(false)
      } catch (error: any) {
        setIsLoadingDevices(false)
        toast.error(error.suggestedMessage ?? 'Falha ao buscar rastreadores')
      }
    },
    [clientId]
  )

  const updateDeviceStatus = useCallback(
    async (index: number, payload: any) => {
      if (!clientId || !devices) return

      const device = devices.data[index]

      try {
        await updateItemStatus(device.relationships?.order.id, [
          { attributes: payload }
        ])

        device.attributes = {
          ...device.attributes,
          ...payload,
          item_status: payload.status
        }
        setDevices({ ...devices })
        toast.success('Rastreador atualizado com sucesso')
      } catch (error: any) {
        toast.error(error.suggestedMessage ?? 'Erro ao atualizar rastreador')
      }
    },
    [clientId, devices]
  )

  const listChips = useCallback(
    async (options: QueryOptions) => {
      if (!clientId) return

      setIsLoadingChips(true)
      try {
        const result = await listClientItems<ClientChip>(
          'chips',
          clientId,
          options
        )
        setChips(result)
        setIsLoadingChips(false)
      } catch (error: any) {
        setIsLoadingChips(false)
        toast.error(error.suggestedMessage ?? 'Falha ao buscar chips')
      }
    },
    [clientId]
  )

  const updateChipStatus = useCallback(
    async (index: number, payload: any) => {
      if (!clientId || !chips) return

      const chip = chips.data[index]

      try {
        await updateItemStatus(chip.relationships?.order.id, [
          { attributes: payload }
        ])

        chip.attributes = {
          ...chip.attributes,
          ...payload,
          item_status: payload.status
        }
        setChips({ ...chips })
        toast.success('Chip atualizado com sucesso')
      } catch (error: any) {
        toast.error(error.suggestedMessage ?? 'Erro ao atualizar chip')
      }
    },
    [chips, clientId]
  )

  return (
    <ClientViewContext.Provider
      value={{
        isLoadingClient,
        client,
        setClient,
        fetchClient,
        identification,
        isRefreshing,
        refreshCorporateData,

        isLoadingSubscriptions,
        subscriptions,
        listSubscriptions,

        isLoadingOrders,
        orders,
        listOrders,

        isLoadingDevices,
        devices,
        listDevices,
        updateDeviceStatus,

        isLoadingChips,
        chips,
        listChips,
        updateChipStatus
      }}
    >
      {children}
    </ClientViewContext.Provider>
  )
}

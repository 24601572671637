import { createContext, ReactNode } from 'react'

export interface ShowConfirmationDialogParams {
  title: ReactNode
  message: ReactNode
  onConfirm: () => any
  onCancel?: () => any
}

const ConfirmationContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  showConfirmationDialog: (_params: ShowConfirmationDialogParams) => {}
})

export { ConfirmationContext }

import { PageHeader } from 'components/PageHeader'
import { SearchBar } from 'components/Search'
import config from 'config'
import { useURLParams } from 'hooks/useURLParams'
import { ReadResult } from 'interfaces/queryOptions'
import { User } from 'models'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { listUsersByClients } from '../services'
import { Pagination } from 'components/Pagination'
import { LinkIcon } from '@heroicons/react/outline'
import { LoadingCircle } from 'components/Loading'
import { LoginAsModal } from '../components/LoginAsModal'
import { translations } from 'modules/Staff/translations'

export function List() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      filters: [],
      search: ''
    }

    const search = query.get('search')
    const filters = query.get('q')

    if (search) params.search = search
    if (filters) params.filters = JSON.parse(atob(filters))

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [search, setSearch] = useState(queryParams.search)
  const [sort] = useState({ username: 1 })
  const [total, setTotal] = useState(0)

  const [data, setData] = useState<ReadResult<User>[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [isLoginAsModalOpen, setIsLoginAsModalOpen] = useState(false)
  const [chosenUser, setChosenUser] = useState({})

  const [noResultsFound, setNoResultsFound] = useState(false)

  const maxItemsPerPage = config.items_per_list_page

  useEffect(() => {
    const fetchData = async () => {
      if (search) {
        await fetchUsers(search, 1)
      }
    }

    fetchData()
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total])

  const fetchUsers = async (search: string, page: number) => {
    const options = {
      limit: maxItemsPerPage,
      search,
      page,
      sort
    }

    try {
      const { count, data } = await listUsersByClients(options)
      setData(data)
      setTotal(count)
      setIsLoading(false)

      if (count == 0) {
        setNoResultsFound(true)
      } else {
        setNoResultsFound(false)
      }
    } catch (err) {
      setIsLoading(false)
      toast.error('Falha ao buscar clientes')
      console.error(err)
    }
  }

  const onPageChange = useCallback(
    async (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)

      await fetchUsers(search, newPage)
    },
    [lastPage]
  )

  const onSearch = useCallback(
    async (term: string) => {
      if (!term || !term.length || term.length <= config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
        toast.warning('Insira um nome de usuário')
        return
      } else {
        const search = query.get('search')
        if (search === term) return
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setIsLoading(true)

      setSearch(term)
      setPage(1)

      await fetchUsers(term, 1)
    },
    [query, replace, maxItemsPerPage, sort]
  )

  return (
    <>
      <PageHeader title="Login como Cliente" action="Procurar cliente" />

      <div className="ml-5">
        <SearchBar value={search} onChange={onSearch} />
        {data.length ? (
          isLoading ? (
            <LoadingCircle />
          ) : (
            <div className="flex flex-col animate-fade-in-down mt-5">
              <div className="-my-2 overflow-x-auto px-4">
                <div className="py-2 align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            NOME
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            USUÁRIO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            EMAIL
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            EMPRESA
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            DOCUMENTO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            CPF
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            PAPEL
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            AÇÕES
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((user, index) => (
                          <tr
                            key={user.id}
                            className={
                              index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                            }
                          >
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xxs">
                              <div
                                title={user.attributes.name}
                                className="text-xs text-left font-semibold text-gray-900 truncate"
                              >
                                {user.attributes.name ?? '-'}
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left max-w-xxs text-sm whitespace-nowrap">
                              <div
                                title={user.attributes.username}
                                className="text-xs text-left text-gray-900 truncate"
                              >
                                {user.attributes.username ?? '-'}
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div
                                title={user.attributes.email}
                                className="text-xs text-left font-semibold text-gray-900 truncate"
                              >
                                {user.attributes.email ?? '-'}
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div
                                title={user.attributes.enterprise_name}
                                className="text-xs text-left font-semibold text-gray-900 truncate"
                              >
                                {user.attributes.enterprise_name ?? '-'}
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div
                                title={user.attributes.document_number}
                                className="text-xs text-left font-semibold text-gray-900 truncate"
                              >
                                {user.attributes.document_number ?? '-'}
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div
                                title={user.attributes.cpf}
                                className="text-xs text-left font-semibold text-gray-900 truncate"
                              >
                                {user.attributes.cpf ?? '-'}
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div
                                title={user.attributes.user_type}
                                className="text-xs text-left font-semibold text-gray-900 truncate"
                              >
                                {
                                  translations.role[
                                    user.attributes.user_type as string
                                  ]
                                }
                              </div>
                            </td>
                            <td className="px-4 py-3 text-xs text-left text-gray-500 max-w-auto">
                              <div className="flex flex-row">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setChosenUser(user)
                                    setIsLoginAsModalOpen(true)
                                  }}
                                  className="-ml-px relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                >
                                  <LinkIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="mx-4">
                <Pagination
                  currentPage={page}
                  pageSize={maxItemsPerPage}
                  itemsOnPage={data.length}
                  totalItems={total}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
          )
        ) : noResultsFound ? (
          <h1 className="font-light">Nenhum usuário encontrado</h1>
        ) : (
          <h1 className="font-light">Procure por um nome de usuário</h1>
        )}
      </div>
      <LoginAsModal
        open={isLoginAsModalOpen}
        setOpen={setIsLoginAsModalOpen}
        user={chosenUser}
      />
    </>
  )
}

import { useConfirmationDialog } from 'components/ConfirmationDialog'
import { LoadPage } from 'components/LoadPage'
import { NotFoundPage } from 'components/NotFoundPage'
import { Client, Order, OrderStatus, Plan, Role } from 'models'
import { subscriptionBaseSteps } from 'modules/Orders/helpers/constants'
import { includeStatus, updateSteps } from 'modules/Orders/helpers/utils'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { show } from '../services/read'
import { toast } from 'react-toastify'
import { ProtectedSection } from 'components/ProtectedSection'
import { CancelButton, SimpleButton } from 'components/FormInputs/Button'
import { StepPanel } from 'modules/Orders/components/StepPanel'
import { ReviewTable } from 'modules/Subscriptions/components/ReviewTable'
import { approve, cancel } from '../services/update'

export function Show() {
  const { subscriptionId } = useParams() as unknown as {
    subscriptionId: string
  }

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)

  const [steps, setSteps] = useState(
    subscriptionBaseSteps.map((step) => Object.assign({}, step))
  )

  const [subscription, setSubscription] = useState<Order>()

  const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState(
    OrderStatus.pending
  )

  const [plan, setPlan] = useState<Partial<Plan>>()
  const [client, setClient] = useState<Partial<Client>>()

  const [showConfirmationDialog] = useConfirmationDialog()

  const updateSubscriptionStatusHistory = useCallback(
    (currentStatus: OrderStatus) => {
      setCurrentSubscriptionStatus(currentStatus)

      if (currentStatus === 'canceled') {
        setSteps(subscriptionBaseSteps)
      } else {
        setSteps(updateSteps(steps, currentStatus))
      }

      return currentStatus
    },
    [steps]
  )

  useEffect(() => {
    if (!subscriptionId) return

    const fetchSubscription = async () => {
      try {
        const subscription = await show(subscriptionId, {
          includes: {
            client: ['id', 'account_name'],
            plan: ['id', 'name', 'nature', 'logistics', 'currency', 'amount'],
            status: ['*'],
            coupon: ['*']
          }
        })

        setSubscription(subscription as Order)

        setPlan({
          id: subscription?.relationships?.plan?.id,
          uuid: subscription?.relationships?.plan?.uuid,
          ...(subscription?.relationships?.plan?.attributes ?? {})
        })

        setClient({
          id: subscription?.relationships?.client?.id,
          uuid: subscription?.relationships?.client?.uuid,
          ...(subscription?.relationships?.client?.attributes ?? {})
        })

        const subscriptionStatus =
          subscription?.relationships?.status[0].attributes.status
        updateSubscriptionStatusHistory(subscriptionStatus)

        setIsLoading(false)
      } catch (err: any) {
        history.push('/subscriptions')
        toast.error(
          err.suggestedMessage ?? 'Falha ao buscar os dados da assinatura'
        )
      }
    }
    fetchSubscription()
  }, [subscriptionId])

  const onCancelConfirmation = useCallback(async () => {
    try {
      await cancel(subscriptionId)
      const data = await show(subscriptionId, {
        attributes: ['id'],
        includes: { status: ['*'] }
      })
      updateSubscriptionStatusHistory(
        data?.relationships?.status[0].attributes.status
      )
      toast.success('Assinatura cancelada com sucesso!')
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Não foi possível cancelar a assinatura!'
      )
    }
  }, [subscriptionId, updateSubscriptionStatusHistory])

  const onCancelRequest = useCallback(() => {
    showConfirmationDialog({
      title: 'Cancelar pedido',
      message: 'Tem certeza que deseja cancelar este pedido?',
      onConfirm: onCancelConfirmation
    })
  }, [
    currentSubscriptionStatus,
    onCancelConfirmation,
    plan?.fiscal_data,
    showConfirmationDialog
  ])

  const onApprovalConfirmation = useCallback(async () => {
    try {
      await approve(subscriptionId)
      const data = await show(subscriptionId, {
        attributes: ['id'],
        includes: { status: [] }
      })
      updateSubscriptionStatusHistory(
        data?.relationships?.status[0].attributes.status
      )
      toast.success('Assinatura aprovada com sucesso!')
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Não foi possível aprovar a assinatura!'
      )
    }
  }, [subscriptionId, updateSubscriptionStatusHistory])

  const onApprovalRequest = useCallback(() => {
    showConfirmationDialog({
      title: 'Aprovar assinatura',
      message: 'Tem certeza que deseja aprovar esta assinatura?',
      onConfirm: onApprovalConfirmation
    })
  }, [onApprovalConfirmation, showConfirmationDialog])

  return isLoading ? (
    <LoadPage />
  ) : !subscription ? (
    <NotFoundPage />
  ) : (
    <div className="overflow-hidden px-4">
      <div className="flex flex-row justify-between items-center py-4 m-4 animate-fade-in-down">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Assinatura: {subscription.token}{' '}
          {currentSubscriptionStatus === 'canceled' && (
            <span className="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              Cancelada
            </span>
          )}
        </h3>

        <div className="gap-x-2 flex flex-row justify-between">
          {includeStatus(currentSubscriptionStatus, [OrderStatus.pending]) && (
            <ProtectedSection roles={[Role.MANAGER, Role.FINANCE]}>
              <SimpleButton onClick={onApprovalRequest}>Aprovar</SimpleButton>
            </ProtectedSection>
          )}
        </div>
      </div>

      <StepPanel steps={steps} />

      <ReviewTable order={subscription} client={client} plan={plan} />

      {currentSubscriptionStatus !== OrderStatus.canceled && (
        <div className="py-6 gap-x-2 flex flex-row justify-between animate-fade-in-down">
          <ProtectedSection roles={[Role.MANAGER, Role.FINANCE]}>
            <div className="ml-3 text-left text-md">
              <label className="font-medium text-gray-700">
                Cancelar assinatura
              </label>
              <div className="mt-2">
                <CancelButton
                  label="Cancelar"
                  onClick={onCancelRequest}
                ></CancelButton>
              </div>
            </div>
          </ProtectedSection>
        </div>
      )}
    </div>
  )
}

import { Enterprise } from 'models/enterprise'
import { Customization } from 'models/customization'
import {
  Address,
  Client,
  ClientAddress,
  AddressType,
  Role,
  Order,
  User
} from 'models'

import { cleanDocument, cleanPhone, cleanUsername } from '../helpers/utils'
import { checkRoles, isNil, omitBy } from 'utils'
import { ReadResult } from 'interfaces/queryOptions'

import { create as baseCreate, createMany } from 'services/billing'
import { create as createEnterpriseApi } from 'services/enterprises'
import { create as createUsersApi } from 'services/users'

export async function createClient(
  attributes: Partial<Client>
): Promise<ReadResult<Client>> {
  await checkRoles([Role.MANAGER, Role.SALES])

  const payload = {
    attributes: buildAttributes(attributes),
    relationships: {
      country: {
        type: 'country',
        id: attributes.country_id ?? 1
      }
    }
  }

  const result = await baseCreate<Client>('/clients', payload)
  return result
}

export async function createAddress(
  clientId: number,
  attributes: Address,
  type: AddressType
): Promise<ReadResult<ClientAddress>> {
  const payload = {
    attributes: {
      type,
      ...omitBy(attributes, isNil)
    },
    relationships: {
      client: {
        type: 'client',
        id: clientId
      }
    }
  }

  const result = await baseCreate<ClientAddress>('/addresses', payload)
  return result
}

export async function createSubscription(
  clientId: number,
  accessPlanId: number,
  couponId: number,
  attributes: Record<string, any>
): Promise<ReadResult<Order>[]> {
  const payload = {
    attributes: omitBy(attributes, isNil),
    relationships: {
      client: {
        type: 'client',
        id: clientId
      },
      plan: {
        type: 'plan',
        id: accessPlanId
      }
    } as Record<string, any>
  }

  if (couponId) {
    payload.relationships = {
      ...payload.relationships,
      coupon: {
        type: 'coupon',
        id: couponId
      }
    }
  }

  const result = await createMany<Order>('/subscriptions', [payload])
  return result
}

export async function createEnterprise(
  clientId: number,
  attributes: { support: boolean; accounts: string[] }
): Promise<ReadResult<Enterprise>> {
  const payload = {
    attributes: {
      support: attributes.support
    },
    relationships: {
      accounts: {
        type: 'accounts',
        attributes: {
          name: attributes.accounts
        }
      }
    }
  }
  const result = await baseCreate<Enterprise>(`/clients/${clientId}`, payload)
  return result
}

export async function createCustomization(
  enterpriseUuid: string,
  imageId: number | undefined,
  attributes: Partial<Customization>
): Promise<ReadResult<Customization>> {
  const payload = {
    attributes: { ...attributes },
    relationships: {
      picture: { type: 'picture', id: imageId }
    }
  }

  const url = `/${enterpriseUuid}/customizations`
  const result = await createEnterpriseApi<Customization>(url, payload)
  return result
}

export async function createUser(
  enterpriseId: number,
  accountRoleId: string,
  attributes: Partial<User>
): Promise<ReadResult<User>> {
  const payload = {
    attributes: {
      name: attributes.name,
      username: cleanUsername(attributes.username),
      email: attributes.email,
      locale: attributes.locale,
      phone1: cleanPhone(attributes.phone_number)
    },
    relationships: {
      enterprise: { type: 'enterprise', id: enterpriseId },
      roles: { type: 'roles', id: accountRoleId }
    }
  }

  const result = await createUsersApi<User>('', [payload])
  return result
}

function buildAttributes(attributes: Partial<Client>) {
  const cleanAttritues = {
    ...attributes,
    document: cleanDocument(attributes.document)
  }

  cleanAttritues.country_id = undefined
  return omitBy(cleanAttritues, isNil)
}

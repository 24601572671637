import { ReadResult } from 'interfaces/queryOptions'
import { Client, Order, Plan, Role } from 'models'
import { Coupon } from 'models/coupon'
import { patch } from 'services/billing'
import { checkRoles, cleanAmount } from 'utils'
import { edit as baseEdit } from 'services/billing'

export type EditSubscription = {
  subscription: ReadResult<Order>
  client: ReadResult<Client>
  accessPlan: ReadResult<Plan>
  coupon?: ReadResult<Coupon>
}

export async function approve(id: number | string): Promise<any> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  const { data } = await patch(`/subscriptions/${id}/approve`)
  return data
}

export async function cancel(id: number | string): Promise<any> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  const { data } = await patch(`/subscriptions/${id}/cancel`)
  return data
}

const transformAttributes = (
  attributes: Record<string, any>
): Record<string, any> => {
  const { minimum_invoice_amount, fine_mode, fine_amount } = attributes

  return {
    minimum_invoice_amount:
      minimum_invoice_amount === ''
        ? null
        : cleanAmount(minimum_invoice_amount),
    fine_mode: fine_amount === '' || fine_amount === 0 ? null : fine_mode,
    fine_amount:
      fine_amount === '' ? 0 : fine_mode === null ? 0 : cleanAmount(fine_amount)
  }
}

export async function edit(
  id: number,
  { subscription, client, accessPlan, coupon }: EditSubscription
): Promise<ReadResult<Order>> {
  const { fine_mode, fine_amount, minimum_invoice_amount } =
    transformAttributes(subscription.attributes)

  const payload = {
    attributes: {
      ordered_at: subscription.attributes.ordered_at,
      discount: subscription.attributes.discount,
      quantity: subscription.attributes.quantity,
      fine_mode,
      fine_amount,
      minimum_invoice_amount
    },
    relationships: {
      client: {
        type: 'client',
        id: client.id
      },
      plan: {
        type: 'plan',
        id: accessPlan.id
      },
      coupon: {
        type: 'coupon',
        id: null as unknown as number
      }
    }
  }

  if (coupon) {
    payload.relationships.coupon = {
      type: 'coupon',
      id: coupon.id
    }
  }

  const response = await baseEdit<Order>('/subscriptions', id, payload)
  return response
}
